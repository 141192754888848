import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Router } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import styles from './App.module.scss';

import LoginDialog from './Dialogs/LoginDialog';
import LogoutDialog from './Dialogs/LogoutDialog';
import ResetPasswordDialog from './Dialogs/ResetPasswordDialog';
import RedeemDialog from './Dialogs/RedeemDialog';
import RequestResetPasswordDialog from './Dialogs/RequestResetPasswordDialog';
import ThrottleRedirectDelayDialog from './Dialogs/ThrottleRedirectDelayDialog';

import Logo from './Components/Logo';
import { getRandomBackground } from './Backgrounds';

import AppController from './Controllers/AppController';

import { MAKE_API } from './constants';

class App extends Component {
  constructor(props) {
    super(props);
    this.history = createHistory(this.props);

    this.state = {
      data: null,
      valid: false,
    };

    AppController.history = this.history;
  }

  componentDidMount() {
    this.checkBackend()
      .then(res => this.setState({ data: res.status }))
      .catch(err => console.log(err));
    this.checkClient()
      .then(res => this.setState({ valid: res }))
      .catch(err => console.log(err));
  }
  // fetching the GET route from the Express server which matches the GET route from server.js
  checkBackend = async () => {
    try {
      const response = await fetch(MAKE_API('health'));
      const body = await response.json();

      if (response.status !== 200) {
        throw new Error(body.message);
      }
      return body;
    } catch (error) {
      throw new Error(error);
    }
  };

  checkClient = async () => {
    try {
      const response = await fetch(MAKE_API('client', this.props.clientId));
      const body = await response.text();

      if (response.status !== 200) {
        throw new Error(body.message);
      }
      return body;
    } catch (error) {
      throw new Error(error);
    }
  };

  render() {
    const app = (
      <Router history={this.history} forceRefresh={false}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/password/reset/:signal">
            <ResetPasswordDialog {...this.props} />
          </Route>
          <Route exact path="/password/forgot">
            <RequestResetPasswordDialog {...this.props} />
          </Route>
          <Route path="/redeem/:entitlementId">
            <RedeemDialog {...this.props} />
          </Route>
          <Route path="/login">
            <LoginDialog {...this.props} />
          </Route>
          <Route path="/logout">
            <LogoutDialog {...this.props} />
          </Route>
        </Switch>
      </Router>
    );

    const background = getRandomBackground();

    const styleObj = {
      backgroundImage: `url(${background})`,
    };

    return (
      <div className={styles.App}>
        <div className={styles.backdrop} style={styleObj}>
          {
            (this.props.clientId && this.state.data && this.state.valid)
              ? app
              : <Logo className={styles.logo} />
          }
        </div>
        <ThrottleRedirectDelayDialog />
      </div>
    );
  }
}

App.propTypes = {
  clientId: PropTypes.string.isRequired,
  callback: PropTypes.string,
};

export default App;
