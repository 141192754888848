import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './Logo.module.scss';

export default function Logo(props) {
  const className = props.variant === 'full' ? styles.logo : styles.chip;
  return (
    <div className={clsx([className, props.className])} />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['full', 'chip'])
};

Logo.defaultProps = {
  className: null,
  variant: 'full'
};
