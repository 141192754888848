function importAll(r) {
  return r.keys().map(item => r(item));
}

const images = importAll(require.context('./images', false, /\.(jpe?g)$/));
const index =  Math.floor(Math.random() * images.length);

export function getRandomBackground () {
  return images[index];
}
