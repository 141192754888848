/* eslint react-hooks/exhaustive-deps: "off" */
import * as React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';

import { HotKeys } from 'react-hotkeys';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { makeStyles } from '@mui/styles';

import { PinInput } from 'react-input-pin-code';
import { useParams } from "react-router-dom";

import AppController from '../Controllers/AppController';

import Logo from '../Components/Logo';

import { MAKE_API } from '../constants';

import styles from './LoginDialog.module.scss';
import { Typography } from '@mui/material';

const errorMessages = {
  500: "Internal Error",
  400: 'Invalid Redemption',
  401: 'Invalid Redemption',
  403: 'Invalid Redemption',
  404: 'Invalid Redemption',
};

const params = new URLSearchParams(window.location.search);
const errorCode = params.get('error');

export default function RedeemDialog(props) {
  const [open,] = React.useState(true);
  const [code, setCode] = React.useState('');
  const [values, setValues] = React.useState(['', '', '', '']);
  const [entitlement, setEntitlement] = React.useState(null);
  const [error, setError] = React.useState(errorCode ? (errorMessages[errorCode] || 'Internal Error') : null);
  const redemptionForm = React.useRef(null);
  const { entitlementId } = useParams();

  React.useEffect(() => {
    // check if the entitlement has been redeemed already by this user
    fetch(MAKE_API('redeem', entitlementId, [`client_id=${props.clientId}`]))
      .then(response => {
        if (response.status === 200) {
          return response.json();
        }
        // otherwise do nothing...
      })
      .then(response => {
        setCode(response.code);
        redemptionForm.current.submit();
      })
      .catch(() => {
        // eat the error
      });
  });

  const useStyles = makeStyles(() => {
    return {
      backdrop: {
        backgroundColor: 'transparent',
      },
      container: {
        '@media(max-width: 600px)': {
          height: '100%',
        }
      },
      paper: {
        content: {
          '@media(max-width: 600px)': {
            position: 'absolute',
            transform: 'translateY(-50%)',
            top: '50%',
          },
        },
        '@media(max-width: 600px)': {
          height: '100%',
          width: '100%',
          margin: '0 !important',
          padding: 0,
          maxWidth: 'unset !important',
          maxHeight: 'unset !important',
        }
      }
    };
  });


  const handleRedeem = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    AppController.enterThrottleRedirectDelay();

    setError(null);

    // TODO: Make this a constant
    if (code.length < 4) {
      setError('Redemption code is required');
      return;
    }

    const body = new FormData();

    body.append('claim', code);

    const redemptionOptions = {
      method: 'POST',
      body,
    };

    fetch(MAKE_API('redeem', entitlementId, [`client_id=${props.clientId}`, 'preflight=true']), redemptionOptions)
      .then(response => {
        if (response.status !== 200) {
          const message = errorMessages[response.status] || 'Internal Error';
          setError(message);
          AppController.exitThrottleRedirectDelay();
        } else {
          redemptionForm.current.submit();
        }
      })
      .catch((error) => {
        console.log(error);
        setError('Internal Error');
        AppController.exitThrottleRedirectDelay();
      });
  };

  const handleCodeChanged = (value, index, values) => {
    setValues(values);
    setError(null);
    setCode(values.join(''));
  };

  const keyMap = {
    redeem: 'Enter',
  };
  const handlers = {
    redeem: handleRedeem,
  };

  const classes = useStyles(props);

  React.useEffect(() => {
    // TODO: Make this a constant
    if (code.length === 4) {
      handleRedeem(null, 'redemptionCodeChanged');
    }
  }, [code]);

  React.useEffect(() => {
    fetch(MAKE_API('entitlement', entitlementId, [`client_id=${props.clientId}`]))
      .then(response => response.text())
      .then(text => {
        return JSON.parse(text);
      })
      .then(entitlement => {
        setEntitlement(entitlement);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [entitlementId]);

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Dialog classes={classes} open={open} disableEscapeKeyDown>
        <form ref={redemptionForm} action={MAKE_API('redeem', entitlementId, [`client_id=${props.clientId}`])} method="post" encType="multipart/form-data">
          {error && (
            <Alert severity="error" onClose={() => { setError(null); }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
          <DialogTitle><Logo variant="chip" className={styles.chip} />Redeem Entitlement</DialogTitle>
          <DialogContent className={styles.pinDialogContent}>
            <Typography variant="h4">Claim Code</Typography>
            {entitlement && entitlement.disclaimer && (
              <div>
                <div className={styles.spacer} />
                <Typography>{entitlement.disclaimer}</Typography>
              </div>
            )}
            <div className={styles.spacer} />
            <PinInput
              type="text"
              autoFocus={true}
              placeholder=''
              values={values}
              onChange={handleCodeChanged}
              size="lg"
              containerStyle={{ justifyContent: 'center' }}
              inputClassName={styles.pinNumber}
              format={(char) => char.toUpperCase() /* redemption codes are all uppercase but we should parameterize this if we change it later */}
            />
            <FormControl sx={{ m: 1, padding: 0, margin: ' 20px 0 0', width: '100%', display: 'none', }} variant="standard">
              <Input
                name="claim"
                id="claim"
                value={code}
                type="hidden"
              />
            </FormControl>
          </DialogContent>
        </form>
      </Dialog>
    </HotKeys>
  );
}

RedeemDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
};
