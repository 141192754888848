import { useEffect, useState } from 'react';

import RingLoader from 'react-spinners/RingLoader';

import { makeStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './ThrottleRedirectDelayDialog.module.scss';

import AppController from '../Controllers/AppController';

const useStyles = makeStyles({
  paper: {
    height: '240px',
    width: '240px',
  },
});

export default function LengthyOperationDialog() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    function open() {
      setOpen(true);
    }
    AppController.on(AppController.throttleStartEvent, open);
    return () => {
      AppController.removeListener(AppController.throttleStartEvent, open);
    };
  });
  useEffect(() => {
    function close() {
      setOpen(false);
    }
    AppController.on(AppController.throttleEndEvent, close);
    return () => {
      AppController.removeListener(AppController.throttleEndEvent, close);
    };
  });

  const classes = useStyles();
  return (
    <Dialog classes={classes} open={open}>
      <DialogTitle>Stand By...</DialogTitle>
      <DialogContent>
        <div className={styles.loader}>
          <RingLoader className={styles.loader} color="black" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

