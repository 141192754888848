import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import RingLoader from 'react-spinners/RingLoader';

import { makeStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Logo from '../Components/Logo';

import styles from './LoginDialog.module.scss';

import { MAKE_API } from '../constants';

const useStyles = makeStyles({
  paper: {
    '@media(max-width: 600px)': {
      height: '100%',
      width: '100%',
      margin: '0 !important',
      padding: 0,
      maxHeight: 'unset !important',
    },
    height: '240px',
    width: '240px',
  },
});

export default function LogoutDialog(props) {
  const logoutForm = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (logoutForm.current) {
        logoutForm.current.submit();
      }
    });
  }, []);

  const classes = useStyles();

  const callback = props.callback && `callback=${props.callback}`;

  return (
    <Dialog classes={classes} open={true}>
      <DialogTitle><Logo variant="chip" className={styles.chip} />Logging out...</DialogTitle>
      <DialogContent>
        <form ref={logoutForm} action={MAKE_API('logout', [`client_id=${props.clientId}`, callback])} method="post" encType="multipart/form-data">
          <div className={styles.loader}>
            <RingLoader className={styles.loader} color="black" />
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

LogoutDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
  callback: PropTypes.string,
};
