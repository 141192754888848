import React from 'react';
import EventEmitter from 'eventemitter3';
import URI from 'urijs';
import lodash from 'lodash';
import HttpStatus from 'http-status-codes';

import DialogController from './DialogController';

const APP_CONTROLLER_CONTEXT = React.createContext();
class AppController extends EventEmitter {

  constructor() {
    super();

    this.ready = new Promise((resolve) => {
      this.on(this.initializedEvent, () => {
        resolve();
      });
    });

    this.a = document.createElement('a');
  }

  get context() {
    return APP_CONTROLLER_CONTEXT;
  }

  set history(history) {
    this._history = history;
  }

  get history() {
    return this._history;
  }

  set store(store) {
    this._store = store;
  }

  get store() {
    return this._store;
  }

  dispatch(action) {
    if (this.store) {
      this.store.dispatch(action);
    }
  }

  initialized() {
    //
    this.emit(this.initializedEvent);
  }

  navigate(location, type = AppController.prototype.navigate.push) {

    if (!this._history) {
        throw new Error('No navigation controller...');
    }

    const parts = URI.parse(location);
    const path = URI.build({
      ...parts,
      hostname: null,
      port: null,
      protocol: null,
    }).toString();

    if (window.location.pathname === path) {
      // eslint-disable-next-line no-console
      console.log(`Navigate to ${path} requested but already landed`);
      return;
    }

    // eslint-disable-next-line no-console
    console.log(`Navigating to ${path}`);

    if (type === AppController.prototype.navigate.replace) {
      this._history.replace(path);
    } else {
      this._history.push(path);
    }
  }

  enterThrottleRedirectDelay() {
    if (this.throttleCount === 0) {
      this.emit(this.throttleStartEvent);
    }
    this.throttleCount += 1;
  }

  exitThrottleRedirectDelay() {
    this.throttleCount -= 1;
    if (this.throttleCount === 0) {
      this.emit(this.throttleEndEvent);
    }
  }

  openURL(url) {
    this.a.href = url;
    this.a.target = '_blank';
    this.a.click();
  }

  /**
   * If the exception was a schema validation error, this
   * function will crack the last validation error message from the validation error
   *
   * @param {Exception} e exception
   */
   crackValidationError(e, unwindDataPath = true) {
    // if (e.isPrototypeOf(SchemaValidationError)) {
    //   const error = e.errors.slice(0).pop();
    //   if (unwindDataPath) {
    //     return error.message;
    //   }
    //   return error.formattedErrorText;
    // }
    return e.message;
  }

  /**
   * Shows a schema validation error dialog. This method should only
   * be called when catching exceptions thrown from `Schema.validate`
   * because if the exception is not a SchemaValidationException then
   * it will show a generic, unformatted message.
   *
   * @param {Exception} e exception
   */
  showValidationError(e) {
    const message = this.crackValidationError(e);
    this.doAlert(message, 'Sorry to have to do this...');
  }

  /**
   * Retrieves the error code from the exception object
   * @param {Exception} e exception
   * @returns {Number=} the error code or
   *    null if an error code could not be discerned from the exception object
   */
  crackErrorCode(e) {
    if (e.status && lodash.isNumber(e.status)) {
      return e.status;
    }
    if (e.statusCode && lodash.isNumber(e.statusCode)) {
      return e.statusCode;
    }
    const status = lodash.get(e, 'response.status');
    if (status && lodash.isNumber(status)) {
      return status;
    }
    const code = lodash.get(e, 'data.error_code');
    if (status && lodash.isNumber(code)) {
      return code;
    }
    return null;
  }

  /**
   * Retrieves the error code from the exception object
   * @param {Exception} e exception
   * @param {Number} code http status code
   * @returns {String=} the formatted error text or null if there is none
   */
  formatHttpStatusText(e, code) {
    if (!code) {
      return null;
    }

    return HttpStatus.getStatusText(code);
  }

  /**
   * Retrieves the error code from the exception object
   * @param {Exception} e exception
   * @param {boolean} unwindDataPath true to format data exception messages a pretty title,
   *                                 false to format data exceptions messages with the complete
   *                                 path of the offending property
   * @returns {String} error message text
   */
  formatExceptionText(e, unwindDataPath = true) {
    let message;

    // if (e.isPrototypeOf(SchemaValidationError)) {
    //   message = this.crackValidationError(e, unwindDataPath);
    // }
    if (!message) {
      message = e.message;
    }

    if (!message) {
      message = lodash.get(e, 'response.data.message');
    }

    if (!message) {
      const code = this.crackErrorCode(e);
      message = this.formatHttpStatusText(e, code);
    }

    if (!message) {
      message = lodash.get(e, 'response.statusText');
    }

    if (!message) {
      message = e.toString();
    }

    return message;
  }

  /**
   * Shows an error dialog with text about the operation that was being performed
   * @param {string} when attempted operation
   * @param {Exception} e exception
   */
  reportError(when, e) {
    console.error(e);
    if (this.crackErrorCode(e) === HttpStatus.BAD_REQUEST && e.message) {
      DialogController.doAlert(e.message, 'On noes...');
    } else {
      const message = this.formatExceptionText(e);
      const text = `${message} occurred while ${when}.  Please try the operation again later...`;
      DialogController.doAlert(text, 'Sorry about this...');
    }
  }
}

AppController.prototype.navigate.replace = 'replace';
AppController.prototype.navigate.push = 'push';
AppController.prototype.statusEvent = 'status';
AppController.prototype.initializedEvent = 'initialized';
AppController.prototype.throttleStartEvent = 'throttle.start';
AppController.prototype.throttleEndEvent = 'throttle.end';
AppController.prototype.throttleCount = 0;

const appController = new AppController();
export default appController;
