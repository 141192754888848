import * as React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { HotKeys } from 'react-hotkeys';
import { useHistory } from "react-router-dom";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { makeStyles } from '@mui/styles';

import { makeHotKeyHandler } from '../Utils/mui-utils';

import AppController from '../Controllers/AppController';

import Logo from '../Components/Logo';

import { MAKE_API } from '../constants';

import styles from './LoginDialog.module.scss';

const errorMessages = {
  400: 'Internal Error',
  401: 'Invalid username or password',
  404: 'Invalid username or password',
};

const params = new URLSearchParams(window.location.search);
const errorCode = params.get('error');

export default function LoginDialog(props) {
  const [open,] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(errorCode ? (errorMessages[errorCode] || 'Internal Error') : null);
  const [showPassword, setShowPassword] = React.useState(false);
  const loginForm = React.useRef(null);
  const history = useHistory();

  const useStyles = makeStyles(() => {
    return {
      backdrop: {
        backgroundColor: 'transparent',
      },
      container: {
        justifyContent: 'right !important',
        '@media(max-width: 600px)': {
          height: '100%',
        }
      },
      root: {
        '@media(min-width: 600px)': {
          marginRight: '10vw',
        }
      },
      paper: {
        '@media(max-width: 600px)': {
          height: '100%',
          width: '100%',
          margin: '0 !important',
          padding: 0,
          maxWidth: 'unset !important',
          maxHeight: 'unset !important',
        }
      }
    };
  });

  const handleEmailChanged = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handlePasswordChanged = (e) => {
    setPassword(e.currentTarget.value);
  };

  const handleForgotPassword = (classes) => {
    history.push(`/password/forgot?client_id=${props.clientId}`);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLogin = (e, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    AppController.enterThrottleRedirectDelay();

    setShowPassword(false);
    setError(null);

    if (email.length < 1 || password.length < 1) {
      setError('Username/e-mail address, and Password are required');
      return;
    }

    const body = new FormData();

    body.append('email', email);
    body.append('password', password);
    const loginOptions = {
      method: 'POST',
      body,
    };

    fetch(MAKE_API('login', [`client_id=${props.clientId}`, 'preflight=true']), loginOptions)
      .then(response => {
        return response.text().then(text => {
          let body;
          try {
            body = JSON.parse(text);
          } catch (e) {
            return {
              response
            };
          }

          return {
            response,
            body: body.message,
          };
        });
      })
      .then(({ response, body }) => {
        if (response.status !== 200) {
          const message = body || errorMessages[response.status] || 'Internal Error';
          setError(message);
          AppController.exitThrottleRedirectDelay();
        } else {
          loginForm.current.submit();
        }
      })
      .catch((error) => {
        console.log(error);
        setError('Internal Error');
        AppController.exitThrottleRedirectDelay();
      });
  };

  const keyMap = {
    login: 'Enter',
  };
  const handlers = {
    login: handleLogin,
  };

  const handleKeyDown = makeHotKeyHandler(keyMap, handlers);

  const classes = useStyles(props);

  const callback = props.callback && `callback=${props.callback}`;

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <Dialog classes={classes} open={open} disableEscapeKeyDown>
        <form ref={loginForm} action={MAKE_API('login', [`client_id=${props.clientId}`, callback])} method="post" encType="multipart/form-data">
          {error && (
            <Alert severity="error" onClose={() => { setError(null); }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
          <DialogTitle><Logo variant="chip" className={styles.chip} />Sign in</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To use to this website, please enter your username or email address and password.
            </DialogContentText>
            <div className={styles.spacer} />

            <FormControl sx={{ m: 1, padding: 0, margin: ' 20px 0 0', width: '100%' }} variant="standard">
              <InputLabel htmlFor="name">Username or Email</InputLabel>
              <Input
                autoFocus
                margin="dense"
                required
                fullWidth
                variant="standard"
                name="name"
                autoComplete="username"
                id="name"
                value={email}
                type="email"
                onKeyDown={handleKeyDown}
                onChange={handleEmailChanged}
              />
            </FormControl>
            <FormControl sx={{ m: 1, padding: 0, margin: ' 20px 0 0', width: '100%' }} variant="standard">
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                margin="dense"
                required
                fullWidth
                name="password"
                autoComplete="current-password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onKeyDown={handleKeyDown}
                onChange={handlePasswordChanged}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Link
              component="button"
              variant="body2"
              onClick={() => handleForgotPassword(classes)}
            >
              Forgot Password
            </Link>
            <Button onClick={handleLogin}>Continue</Button>
          </DialogActions>
        </form>
      </Dialog>
    </HotKeys>
  );
}

LoginDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
  callback: PropTypes.string,
};