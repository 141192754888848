import * as React from 'react';
import PropTypes from 'prop-types';
import DialogController, { ManagedDialog } from '../Controllers/DialogController';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import isEmail from 'validator/lib/isEmail';

import { HotKeys } from 'react-hotkeys';
import { withRouter } from 'react-router';

import { makeHotKeyHandler } from '../Utils/mui-utils';

import Logo from '../Components/Logo';

import styles from './RequestResetPasswordDialog.module.scss';

import AppController from '../Controllers/AppController';

import { MAKE_API, MAKE_REQUEST } from '../constants';

const sx = {
  '& .MuiDialog-paper': {
    '@media(max-width: 600px)': {
      height: '100%',
      width: '100%',
      maxWidth: 'unset !important',
      maxHeight: 'unset !important',
      margin: '0 !important',
    }
  }
};

class RequestResetPasswordDialog extends ManagedDialog {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      open: true,
    };

    this.handleEmailChanged = this.handleEmailChanged.bind(this);
    this.handleDispatchRequest = this.handleDispatchRequest.bind(this);

    this.keyMap = {
      login: 'Enter',
    };
    this.handlers = {
      login: this.handleDispatchRequest
    };

    this.hotKeyHandler = makeHotKeyHandler(this.keyMap, this.handlers);
  }

  handleEmailChanged(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleDispatchRequest() {
    if (this.state.email.length === 0) {
      this.setState({
        error: 'You must provide an email address or username'
      });
      return;
    }

    this.setState({
      error: null
    });

    fetch(MAKE_API('reset', [`user=${this.state.email}`, `client_id=${this.props.clientId}`]))
      .finally(() => {
        let fillIn = '';
        if (isEmail(this.state.email)) {
          fillIn = this.state.email;
        } else {
          fillIn = `the email address we have on file for ${this.state.email}`;
        }
        DialogController.doAlert(`An email with a reset password link has been sent to ${fillIn}.`, 'Reset Password', 'ok')
          .then(() => {
            AppController.enterThrottleRedirectDelay();
            setTimeout(() => {
              AppController.exitThrottleRedirectDelay();
              const callback = this.props.callback && `callback=${this.props.callback}`;
              this.props.history.push(MAKE_REQUEST('login', [`client_id=${this.props.clientId}`, callback]));
            }, 800);
          });
      });
  }

  render() {
    return (
      <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <Dialog sx={sx} open={this.state.open}>
          <DialogTitle><Logo variant="chip" className={styles.chip} />Reset Password</DialogTitle>
          {this.state.error && (
            <Alert severity="error" onClose={() => { this.setState({ error: null }); }}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          )}
          <DialogContent>
            <Typography variant="h5">
              Forgot your password?
            </Typography>
            <DialogContentText sx={{ marginTop: '20px' }}>
              Just provide us with your username or email address so we can find you in our system and send you reset instructions.
            </DialogContentText>
            <div className={styles.form}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="username"
                name="username"
                autoComplete="username"
                label="Username or Email"
                type="email"
                fullWidth
                variant="standard"
                value={this.state.email}
                onChange={this.handleEmailChanged}
                onKeyDown={this.hotKeyHandler}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.handleDispatchRequest}>Continue</Button>
          </DialogActions>
        </Dialog>
      </HotKeys>
    );
  }
}

RequestResetPasswordDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  callback: PropTypes.string,
};

RequestResetPasswordDialog.defaultProps = {
  onClose: () => { },
};

export default withRouter(RequestResetPasswordDialog);