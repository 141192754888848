import { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';


import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { HotKeys } from 'react-hotkeys';

import DialogController from '../Controllers/DialogController';
import RequestResetPasswordDialog from './RequestResetPasswordDialog';

import AppController from '../Controllers/AppController';

import Logo from '../Components/Logo';
import styles from './LoginDialog.module.scss';
import { makeHotKeyHandler } from '../Utils/mui-utils';

import { MAKE_API, MAKE_REQUEST } from '../constants';

const sx = {
  '& .MuiDialog-paper': {
    '@media(max-width: 600px)': {
      height: '100%',
      width: '100%',
      maxHeight: 'unset !important',
      margin: '0 !important',
    }
  }
};

class ResetPasswordDialog extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const userName = params.get('username');

    this.state = {
      password: '',
      confirmPassword: '',
      error: null,
      open: true,
      showNewLinkButton: false,
      showPassword: false,
      email,
      userName,
    };

    this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
    this.handleConfirmPasswordChanged = this.handleConfirmPasswordChanged.bind(this);
    this.handleDispatchRequest = this.handleDispatchRequest.bind(this);
    this.handleRequestNewLink = this.handleRequestNewLink.bind(this);
    this.handleToggleShowPassword = this.handleToggleShowPassword.bind(this);

    this.keyMap = {
      login: 'Enter',
    };
    this.handlers = {
      login: this.handleDispatchRequest
    };

    this.hotKeyHandler = makeHotKeyHandler(this.keyMap, this.handlers);
  }

  handleToggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handlePasswordChanged(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleRequestNewLink() {
    this.setState({
      open: false,
    });

    new Promise((resolve) => {
      DialogController.doModal(<RequestResetPasswordDialog clientId={this.props.clientId} callback={this.props.callback} onClose={resolve} />);
    })
      .then(new Promise((resolve) => {
        AppController.enterThrottleRedirectDelay();
        setTimeout(() => {
          resolve();
          AppController.exitThrottleRedirectDelay();
        }, 800);
      }))
      .then(() => {
        const callback = this.props.callback && `callback=${this.props.callback}`;
        this.props.history.push(MAKE_REQUEST('login', [`client_id=${this.props.clientId}`, callback]));
      });
  }

  handleConfirmPasswordChanged(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }


  handleDispatchRequest() {
    const { password, confirmPassword } = this.state;

    this.setState({ message: null });

    if (password !== confirmPassword) {
      this.setState({ error: 'Passwords do not match...Please try again...' });
    } else {
      const body = new FormData();
      body.append('password', password);
      const resetOptions = {
        method: 'POST',
        body,
      };

      const signal = this.props.match.params.signal;
      fetch(MAKE_API(`reset`, signal, [`client_id=${this.props.clientId}`]), resetOptions)
        .then(response => {
          return new Promise((resolve) => {
            response.text().then(text => {
              resolve({
                text,
                response
              });
            });
          });
        })
        .then(({ text, response }) => {
          if (response.status !== 200) {
            if (response.status === 404) {
              this.setState({ error: 'The reset password link has expired...You can request a new link below', showNewLinkButton: true });
            } else {
              this.setState({ error: 'Internal Error...Please try again...' });
            }
            console.log(text.message);
          } else {
            AppController.enterThrottleRedirectDelay();
            setTimeout(() => {
              AppController.exitThrottleRedirectDelay();
              const callback = this.props.callback && `callback=${this.props.callback}`;
              this.props.history.push(MAKE_REQUEST('login', [`client_id=${this.props.clientId}`, callback]));
            }, 800);
          }
        })
        .catch((error) => {
          this.setState({ error: error.message });
          console.log(error);
        });
    }
  }

  render() {
    return (
      <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
        <Dialog sx={sx} open={this.state.open}>
          <DialogTitle><Logo variant="chip" className={styles.chip} />Reset Password</DialogTitle>
          {this.state.error && (
            <Alert severity="error" onClose={() => { this.setState({ error: null }); }}>
              <AlertTitle>Error</AlertTitle>
              {this.state.error}
            </Alert>
          )}
          <DialogContent>
            <DialogContentText sx={{ marginTop: '20px' }}>
              Please enter your new password and confirm the change in the confirmation field below
            </DialogContentText>
            <TextField
              sx={{ display: 'none' }}
              autoFocus
              required
              margin="dense"
              id="name"
              name="username"
              label="Username or Email"
              type="email"
              fullWidth
              variant="standard"
              autoComplete="username"
              value={this.state.email}
            />
            <FormControl sx={{ m: 1, padding: 0, margin: ' 20px 0 0', width: '100%' }} variant="standard">
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <Input
                margin="dense"
                required
                fullWidth
                autoComplete="new-password"
                id="new-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onKeyDown={this.hotKeyHandler}
                onChange={this.handlePasswordChanged}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleToggleShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ m: 1, padding: 0, margin: ' 20px 0 0', width: '100%' }} variant="standard">
              <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
              <Input
                margin="dense"
                required
                fullWidth
                name="password"
                autoComplete="new-password"
                id="confirm-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.confirmPassword}
                onKeyDown={this.hotKeyHandler}
                onChange={this.handleConfirmPasswordChanged}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleToggleShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {this.state.showNewLinkButton && (
              <Link
                component="button"
                variant="body2"
                onClick={this.handleRequestNewLink}
              >
                Request New Link
              </Link>
            )}
            <Button variant="outlined" onClick={this.handleDispatchRequest}>Continue</Button>
          </DialogActions>
        </Dialog>
      </HotKeys>
    );
  }
}

ResetPasswordDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
  callback: PropTypes.string,
};

export default withRouter(ResetPasswordDialog);