import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const params = new URLSearchParams(window.location.search);
const clientId = params.get('client_id');
const callback = params.get('callback');

ReactDOM.render(
  <React.StrictMode>
    <App clientId={clientId} callback={callback} />
  </React.StrictMode>,
  document.getElementById('root')
);
